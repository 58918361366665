import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const { user, profileComplete, onBoardingComplete } = useAuthContext();

  const data = useMemo(
    () =>
      profileComplete && onBoardingComplete
        ? user?.role === 'aggregator'
          ? [
              {
                subheader: t('Dashboard'),
                items: [
                  {
                    title: t('Home'),
                    path: paths.dashboard.root,
                    icon: ICONS.analytics,
                  },
                  {
                    title: t('Distributors'),
                    path: paths.dashboard.distributors.root,
                    icon: ICONS.user,
                    children: [
                      { title: t('Distributors List'), path: paths.dashboard.distributors.list },
                      {
                        title: t('Onboarding History'),
                        path: paths.dashboard.distributors.onboarding,
                      },
                      { title: t('Leads'), path: paths.dashboard.distributors.leads },
                    ],
                  },
                ],
              },
            ]
          : [
              // OVERVIEW
              // ----------------------------------------------------------------------
              {
                subheader: t('Dashboard'),
                items: [
                  {
                    title: t('Home'),
                    path: paths.dashboard.root,
                    icon: ICONS.analytics,
                  },
                  {
                    title: t('Orders'),
                    path: paths.dashboard.order.root,
                    icon: ICONS.order,
                  },
                  {
                    title: t('Catalogue'),
                    path: paths.dashboard.catalogue.root,
                    icon: ICONS.product,
                    children: [
                      { title: t('Products List'), path: paths.dashboard.catalogue.root },
                      { title: t('Update History'), path: paths.dashboard.catalogue.updateHistory },
                    ],
                  },
                  {
                    title: t('Retailer'),
                    path: paths.dashboard.retailers.root,
                    icon: ICONS.user,
                    children: [
                      { title: t('Retailers List'), path: paths.dashboard.retailers.list },
                      {
                        title: t('Onboarding History'),
                        path: paths.dashboard.retailers.onboarding,
                      },
                      // { title: t('Leads'), path: paths.dashboard.retailers.leads },
                    ],
                  },

                  {
                    title: t('CPV'),
                    path: paths.dashboard.cpv.root,
                    icon: ICONS.file,
                  },
                  {
                    title: t('Invoice'),
                    path: paths.dashboard.invoice.root,
                    icon: ICONS.invoice,
                  },
                  {
                    title: t('Prepaid'),
                    path: paths.dashboard.prepaid.root,
                    icon: ICONS.banking,
                    children: [
                      { title: t('Statement'), path: paths.dashboard.prepaid.statement },
                      { title: t('Pending/Failed'), path: paths.dashboard.prepaid.pending_failed },
                    ],
                  },
                  {
                    title: t('enquiry'),
                    path: paths.dashboard.enquiry.root,
                    icon: ICONS.mail,
                    children: [
                      { title: t('Existing Retailers'), path: paths.dashboard.enquiry.retailers },
                      { title: t('New Retailers'), path: paths.dashboard.enquiry.new_retailers },
                      { title: t('Resolved'), path: paths.dashboard.enquiry.resolved },
                    ],
                  },
                  {
                    title: t('Delivery Person'),
                    path: paths.dashboard.delivery_person.root,
                    icon: ICONS.ecommerce,
                  },
                  {
                    title: t('Campaign'),
                    path: '#campaign-disabled',
                    icon: ICONS.calendar,
                    disabled: true,
                    info: (
                      <Label color="error" endIcon={<Iconify icon="ph:code-fill" />}>
                        dev
                      </Label>
                    ),
                  },
                ],
              },
            ]
        : [
            {
              subheader: t('Dashboard'),
              items: [
                {
                  title: t('Setup'),
                  path: paths.dashboard.root,
                  icon: ICONS.lock,
                },
              ],
            },
          ],
    [t, user, profileComplete, onBoardingComplete]
  );

  return data;
}
