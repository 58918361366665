import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import MainLayout from 'src/layouts/main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  const ProductDetailsPage = lazy(() => import('src/pages/product/details'));
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE

    {
      path: '/',
      element: <Navigate to="dashboard" replace />,
    },
    {
      path: 'catalogue/:distributor_id/:slug/:id',
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <ProductDetailsPage />
        </Suspense>
      ),
    },
    ...authRoutes,
    ...dashboardRoutes,
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
