import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductUpdateHistoryPage = lazy(() => import('src/pages/dashboard/product/updateHistory'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));

// CATALOGUE
const CatalogueListPage = lazy(() => import('src/pages/dashboard/product/list'));
const CatalogueUpdateHistoryPage = lazy(() => import('src/pages/dashboard/product/updateHistory'));
const CatalogueCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const CatalogueCampaignsPage = lazy(() => import('src/pages/dashboard/product/campaigns'));
const CatalogueSendCampaignsPage = lazy(() => import('src/pages/dashboard/product/send-campaigns'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
const OrderCreatePage = lazy(() => import('src/pages/dashboard/order/create'));
// PREPAID
const PrepaidListPage = lazy(() => import('src/pages/dashboard/prepaid/statement'));
const PrepaidPendingFailedPage = lazy(() => import('src/pages/dashboard/prepaid/pending-failed'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// ENQUIRY
const NewEnquiryListPage = lazy(() => import('src/pages/dashboard/enquiry/list/new'));
const ResolvedEnquiryListPage = lazy(() => import('src/pages/dashboard/enquiry/list/resolved'));
const ExistingEnquiryListPage = lazy(() => import('src/pages/dashboard/enquiry/list/existing'));
// CPV
const CPVListPage = lazy(() => import('src/pages/dashboard/cpv/list'));
const CPVNewPage = lazy(() => import('src/pages/dashboard/cpv/new'));
// RETAILER
const RetailerListPage = lazy(() => import('src/pages/dashboard/retailer/list'));
const RetailerOnboardingPage = lazy(() => import('src/pages/dashboard/retailer/onboarding'));
const RetailerLeadsPage = lazy(() => import('src/pages/dashboard/retailer/leads'));
const RetailerCreatePage = lazy(() => import('src/pages/dashboard/retailer/new'));
const RetailerEditPage = lazy(() => import('src/pages/dashboard/retailer/edit'));
// DELIVERY BOY
const DeliveryPersonsListPage = lazy(() => import('src/pages/dashboard/delivery-person/list'));
const DeliveryPersonsCreatePage = lazy(() => import('src/pages/dashboard/delivery-person/new'));
const DeliveryPersonsEditPage = lazy(() => import('src/pages/dashboard/delivery-person/edit'));
// DISTRIBUTOR
const DistributorListPage = lazy(() => import('src/pages/aggregator/distributor/list'));
const DistributorCataloguePage = lazy(() => import('src/pages/aggregator/distributor/catalogue'));
const DistributorOnboardingPage = lazy(() => import('src/pages/aggregator/distributor/onboarding'));
const DistributorLeadsPage = lazy(() => import('src/pages/aggregator/distributor/leads'));
const DistributorCreatePage = lazy(() => import('src/pages/aggregator/distributor/new'));
const DistributorEditPage = lazy(() => import('src/pages/aggregator/distributor/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },
      {
        path: 'retailers',
        children: [
          { element: <RetailerListPage />, index: true },
          { path: 'list', element: <RetailerListPage /> },
          { path: 'new', element: <RetailerCreatePage /> },
          { path: 'onboarding', element: <RetailerOnboardingPage /> },
          { path: 'leads', element: <RetailerLeadsPage /> },
          { path: ':id/edit', element: <RetailerEditPage /> },
        ],
      },
      {
        path: 'delivery-person',
        children: [
          { element: <DeliveryPersonsListPage />, index: true },
          { path: 'list', element: <DeliveryPersonsListPage /> },
          { path: 'new', element: <DeliveryPersonsCreatePage /> },
          { path: ':id/edit', element: <DeliveryPersonsEditPage /> },
        ],
      },
      {
        path: 'cpv',
        children: [
          { element: <CPVListPage />, index: true },
          { path: 'list', element: <CPVListPage /> },
          { path: ':id/new', element: <CPVNewPage /> },
        ],
      },
      {
        path: 'distributors',
        children: [
          { element: <DistributorListPage />, index: true },
          { path: 'list', element: <DistributorListPage /> },
          { path: 'new', element: <DistributorCreatePage /> },
          { path: 'onboarding', element: <DistributorOnboardingPage /> },
          { path: 'leads', element: <DistributorLeadsPage /> },
          { path: ':id/edit', element: <DistributorEditPage /> },
          { path: ':id/catalogue', element: <DistributorCataloguePage /> },
        ],
      },
      {
        path: 'enquiry',
        children: [
          { element: <ExistingEnquiryListPage />, index: true },
          {
            path: 'list',
            children: [
              { path: 'retailers', element: <ExistingEnquiryListPage /> },
              { path: 'new-retailers', element: <NewEnquiryListPage /> },
              { path: 'resolved', element: <ResolvedEnquiryListPage /> },
            ],
          },
        ],
      },
      { path: 'account', element: <UserAccountPage /> },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: 'update-history', element: <ProductUpdateHistoryPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'catalogue',
        children: [
          { element: <CatalogueListPage />, index: true },
          { path: 'list', element: <CatalogueListPage /> },
          { path: 'update-history', element: <CatalogueUpdateHistoryPage /> },
          { path: 'new', element: <CatalogueCreatePage /> },
          { path: 'send-campaigns', element: <CatalogueSendCampaignsPage /> },
          { path: 'campaigns', element: <CatalogueCampaignsPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: 'new', element: <OrderCreatePage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'prepaid',
        children: [
          { element: <PrepaidListPage />, index: true },
          { path: 'statement', element: <PrepaidListPage /> },
          { path: 'pending-failed', element: <PrepaidPendingFailedPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
